<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    getQueryVariable() {
      let href = window.location.href;
      let query = href.substring(href.indexOf("?") + 1);
      let vars = query.split("&");
      let obj = {};
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        obj[pair[0]] = pair[1];
      }
      return obj;
    },
  },

  mounted() {
    if (this.getQueryVariable().id) {
      this.$ajax({
        method: "GET",
        url: `/sys/user/info`,
      }).then((res) => {
        // console.log(res);
        sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
      });
      // console.log(this.getQueryVariable());
      sessionStorage.setItem("token", this.getQueryVariable().id);
      if (this.getQueryVariable().type) {
        sessionStorage.setItem("devtools", true);
      } else {
        sessionStorage.setItem("devtools", false);
      }
      let data={};
      if(this.getQueryVariable().data!=='undefined'){
        data=decodeURIComponent(decodeURIComponent(this.getQueryVariable().data));
        data=JSON.parse(data)
      }
      let url = this.getQueryVariable().url.replaceAll("%2F", "/");
      let urls = url;
      url = url.split("/");
      let ruters = {
        path: "/" + url[1],
        name: url[1],
        component: () => import("../src/" + urls),
      };
      // console.log(urls);
      this.$router.addRoute(ruters);
      this.$router.options.routes.push(ruters);
      this.$router.push({ path: "/" + url[1],query:data});
      // console.log( this.$router);
    }

    this.$nextTick(() => {
      // 初始化uni.webview
      document.addEventListener("UniAppJSBridgeReady", function () {
        // console.log('初始化uniapp的API成功');
        /* eslint-disable*/
        uni.getEnv(function (res) {
          // console.log('当前环境：' + JSON.stringify(res));
        });
      });
    });
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style-type: none;
}

#app {
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
