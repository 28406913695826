import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./config/axios";
import "../static/rem.js";

import {
  Form,
  Field,
  Popup,
  CellGroup,
  DatePicker,
  Button,
  NumberKeyboard,
  Notify,
  Toast,
  Picker,
  Cell,
  Checkbox,
  CheckboxGroup,
  Dialog,
  PickerGroup,
  TimePicker,
  Uploader,
  Icon,
  PullRefresh,
  List,
  Tab,
  Overlay,
  Tabs,
} from "vant";

// 2. 引入组件样式
import "vant/lib/index.css";

const app = createApp(App);
app.config.globalProperties.iptFoucus = () => {
  document.activeElement.blur();
};
app.config.globalProperties.devtools = JSON.parse(
  sessionStorage.getItem("devtools")
);
app.config.globalProperties.$ajax = axios;
app
  .use(Tab)
  .use(Overlay)
  .use(Tabs)
  .use(PullRefresh)
  .use(List)
  .use(Form)
  .use(Icon)
  .use(TimePicker)
  .use(Uploader)
  .use(Field)
  .use(PickerGroup)
  .use(Checkbox)
  .use(Dialog)
  .use(CheckboxGroup)
  .use(Popup)
  .use(Cell)
  .use(CellGroup)
  .use(DatePicker)
  .use(Button)
  .use(Notify)
  .use(Toast)
  .use(Picker)
  .use(NumberKeyboard)
  .use(Picker)
  .use(store)
  .use(router)
  .mount("#app");
