import axios from "axios";
import Config from "./base.js";
import { showDialog } from "vant";

axios.defaults.baseURL = Config.baseUrl;
// axios.defaults.timeout = 3 * 1000; // 3s

// 添加請求攔截器
axios.interceptors.request.use(
  function (config) {
    // config.headers.token = "cda9832595f0b2ae8c487c1e53750ee2";
    config.headers.token = sessionStorage.getItem("token");

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
this;

axios.interceptors.response.use(
  function (response) {
    if (response.data.code != 0 ) {
      showDialog({
        message: response.data.msg,
      });
      if (response.data === undefined || response.data.code === undefined) {
        showDialog({
          message: "网络异常，请检查网络",
        });
      }
      if (response.data.msg == "token失效，请重新登录") {
        uni.navigateTo({
          url: "/pages/login/login",
        });
      }
      if (response.data.code == "401") {
        showDialog({
          message: "登录过期，请重新登录",
        });
        uni.navigateTo({
          url: "/pages/login/login",
        });
      }
     
    }
    return response;
  },
  function (error) {
    // 對響應錯誤作點什麼
    return Promise.reject(error);
  }
);

// export default baseUrl;
